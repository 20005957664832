<template>
  <div class="register-container">
    <el-container>
      <el-header class="register-header">
        <div class="register-header-container">
          <div class="register-logo">
            <img
              class="register-img"
              src="../assets/images/login/logo.png"
              alt=""
            />
          </div>
          <div class="register-title">
            <img
              class="title-img"
              src="../assets/images/login/platform.png"
              alt=""
            />
          </div>
        </div>
      </el-header>
      <el-main class="register-main">
        <div class="register-body-container">
          <div class="register-body-title">
            <img src="../assets/images/register/register-t.png" alt="" />
          </div>
          <div class="register-body-form">
            <el-form
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
            >
              <div class="applicant-info">申请调解人信息</div>
              <div class="applicant-info-form">
                <el-form-item label="组织名称" prop="organizationName">
                  <el-input
                    type="text"
                    v-model="ruleForm.organizationName"
                    placeholder="请输入组织名称"
                    @input="checkOrganizationName"
                  ></el-input>
                </el-form-item>
                <el-form-item label="统一社会信用代码" prop="creditCode">
                  <el-input
                    type="text"
                    v-model="ruleForm.creditCode"
                    @input="checkCreditCode"
                    placeholder="请输入统一社会信用代码"
                  ></el-input>
                </el-form-item>
                <el-form-item label="组织地址" prop="organizationAddress">
                  <el-input
                    type="text"
                    v-model="ruleForm.organizationAddress"
                    @input="checkOrganizationAddress"
                    placeholder="请输入组织地址"
                  ></el-input>
                </el-form-item>
                <el-form-item label="法人姓名" prop="legalName">
                  <el-input
                    type="text"
                    v-model="ruleForm.legalName"
                    @input="checkLegalName"
                    placeholder="请输入法定代表人姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="法人身份证号" prop="legalNumber">
                  <el-input
                    type="text"
                    v-model="ruleForm.legalNumber"
                    @input="checkLegalNumber"
                    placeholder="请输入法定代表人身份证号"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="operator-info">操作人信息</div>
              <div class="operator-info-form">
                <el-form-item label="操作人姓名" prop="operatorName">
                  <el-input
                    type="text"
                    v-model="ruleForm.operatorName"
                    @input="checkOperatorName"
                    placeholder="请输入操作人姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="操作人身份证号" prop="operatorNumber">
                  <el-input
                    type="text"
                    v-model="ruleForm.operatorNumber"
                    @input="checkOperatorNumber"
                    placeholder="请输入操作人身份证号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="操作人邮箱" prop="operatorEmail">
                  <el-input
                    type="email"
                    v-model="ruleForm.operatorEmail"
                    @input="checkOperatorEmail"
                    placeholder="请输入操作人邮箱"
                  ></el-input>
                </el-form-item>
                <el-form-item label="操作人手机号" prop="operatorPhone">
                  <el-input
                    type="number"
                    v-model="ruleForm.operatorPhone"
                    @input="checkOperatorPhone"
                    placeholder="请输入操作人手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="登录密码" prop="operatorPassword">
                  <el-input
                    type="text"
                    v-model="ruleForm.operatorPassword"
                    placeholder="请输入登录密码"
                    @input="checkOperatorPassword"
                  ></el-input>
                </el-form-item>
                <el-form-item label="登录密码确认" prop="operatorConfirmPwd">
                  <el-input
                    type="text"
                    v-model="ruleForm.operatorConfirmPwd"
                    @input="checkOperatorConfirmPwd"
                    placeholder="请再次输入登录密码"
                  ></el-input>
                </el-form-item>
              </div>

              <el-form-item>
                <el-button
                  class="register-btn"
                  :class="{
                    'register-btn-isRegisterBtn': isRegisterBtn == true,
                    'register-btn': isRegisterBtn == false,
                  }"
                  type="primary"
                  @click="submitForm('ruleForm')"
                  >注册</el-button
                >
              </el-form-item>
            </el-form>
            <div class="protocol-tip">
              确认注册即表示同意
              <a href="javascript:void(0)">《仲裁查询平台用户服务协议》</a>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer class="register-footer">
        <div class="register-footer-container">
          <div class="register-footer-address">
            地址：江西省景德镇市珠山区昌江大道瓷都晚报5楼
          </div>
          <div class="register-footer-tip">
            Copyright&copy;2021-2021 景德镇仲裁委 版权所有
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import check from "../utils/check.js";
// import { getRegister } from '@/api/register'
// import request from '@/utils/request'

export default {
  name: "Register",
  data() {
    let validorganizationName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("组织名称不能为空"));
      } else {
        callback();
      }
    };
    let validcreditCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("统一社会信用代码不能为空"));
      } else {
        callback();
      }
    };
    let validorganizationAddress = (rule, value, callback) => {
      if (!value) {
        callback(new Error("组织地址不能为空"));
      } else {
        callback();
      }
    };
    let validlegalName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("法人姓名不能为空"));
      } else {
        callback();
      }
    };
    let validlegalNumber = (rule, value, callback) => {
      if (!value) {
        callback(new Error("法人身份证号不能为空"));
      } else if (!check.isvalidIdNumber(value)) {
        callback(new Error("身份证输入不合法"));
      } else {
        callback();
      }
    };

    let validoperatorName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("操作人姓名不能为空"));
      } else {
        callback();
      }
    };
    let validoperatorNumber = (rule, value, callback) => {
      if (!value) {
        callback(new Error("操作人身份证号不能为空"));
      } else if (!check.isvalidIdNumber(value)) {
        callback(new Error("身份证输入不合法"));
      } else {
        callback();
      }
    };
    let validoperatorEmail = (rule, value, callback) => {
      if (!value) {
        callback(new Error("操作人邮箱不能为空"));
      } else if (!check.isvalidEmail(value)) {
        callback(new Error("请输入正确的邮箱格式"));
      } else {
        callback();
      }
    };
    let validoperatorPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号码不能为空"));
      } else if (!check.isvalidPhone(value)) {
        callback(new Error("请输入正确手机号码"));
      } else {
        callback();
      }
    };
    let validoperatorPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("密码不能为空"));
      } else {
        callback();
      }
    };
    let validoperatorConfirmPwd = (rule, value, callback) => {
      if (!value) {
        callback(new Error("确认密码不能为空"));
      } else if (value != this.ruleForm.operatorPassword) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      isRegisterBtn: false, // 注册按钮高亮显示

      ruleForm: {
        organizationName: "", // 组织名称
        creditCode: "", // 统一社会信用代码
        organizationAddress: "", // 组织地址
        legalName: "", // 法人姓名
        legalNumber: "", // 法人身份证号

        operatorName: "", // 操作人姓名
        operatorNumber: "", // 操作人身份证号
        operatorEmail: "", // 操作人邮箱
        operatorPhone: "", // 操作人手机号
        operatorPassword: "", // 登录密码
        operatorConfirmPwd: "", // 登录密码确认
      },
      rules: {
        organizationName: [
          { validator: validorganizationName, trigger: "blur", required: true },
        ],
        creditCode: [
          { validator: validcreditCode, trigger: "blur", required: true },
        ],
        organizationAddress: [
          {
            validator: validorganizationAddress,
            trigger: "blur",
            required: true,
          },
        ],
        legalName: [
          { validator: validlegalName, trigger: "blur", required: true },
        ],
        legalNumber: [
          { validator: validlegalNumber, trigger: "blur", required: true },
        ],

        operatorName: [
          { validator: validoperatorName, trigger: "blur", required: true },
        ],
        operatorNumber: [
          { validator: validoperatorNumber, trigger: "blur", required: true },
        ],
        operatorEmail: [
          { validator: validoperatorEmail, trigger: "blur", required: true },
        ],
        operatorPhone: [
          { validator: validoperatorPhone, trigger: "blur", required: true },
        ],
        operatorPassword: [
          { validator: validoperatorPassword, trigger: "blur", required: true },
        ],
        operatorConfirmPwd: [
          {
            validator: validoperatorConfirmPwd,
            trigger: "blur",
            required: true,
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid);
        if (valid) {
          let name = this.ruleForm.organizationName;
          let credentNum = this.ruleForm.creditCode;
          let credentAddress = this.ruleForm.organizationAddress;
          let legalName = this.ruleForm.legalName;
          let legalCredentNum = this.ruleForm.legalNumber;
          let name2 = this.ruleForm.operatorName;
          let credentNum2 = this.ruleForm.operatorNumber;
          let email = this.ruleForm.operatorEmail;
          let phone = this.ruleForm.operatorPhone;
          let password = this.ruleForm.operatorPassword;
          let password2 = this.ruleForm.operatorConfirmPwd;
          this.$axios
            .post(
              `/web/web/proposer/regist?name=${name}&credentNum=${credentNum}&legalName=${legalName}&legalCredentNum=${legalCredentNum}&name2=${name2}&credentNum2=${credentNum2}&phone=${phone}&email=${email}&password=${password}`
            )
            .then((res) => {
              if (res && res.data.code == 0 && res.status == 200) {
                this.$message({
                  message: "注册成功，前往登陆！",
                  type: "success",
                });
                this.$router.push("/");
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: "请求异常，服务器出错",
              });
            });
        } else {
          // console.log("提交表单失败error submit!!");
          return false;
        }
      });
    },
    fn() {
      if (
        this.ruleForm.organizationName != "" &&
        this.ruleForm.creditCode != "" &&
        this.ruleForm.organizationAddress != "" &&
        this.ruleForm.legalName != "" &&
        this.ruleForm.legalNumber != "" &&
        this.ruleForm.operatorName != "" &&
        this.ruleForm.operatorNumber != "" &&
        this.ruleForm.operatorEmail != "" &&
        this.ruleForm.operatorPhone != "" &&
        this.ruleForm.operatorPassword != "" &&
        this.ruleForm.operatorConfirmPwd != ""
      ) {
        this.isRegisterBtn = true;
      } else {
        this.isRegisterBtn = false;
      }
    },
    // 组织名称
    checkOrganizationName(e) {
      this.$options.methods.fn.bind(this)();
      this.ruleForm.organizationName = e;
    },
    // 统一社会信用代码
    checkCreditCode(e) {
      this.$options.methods.fn.bind(this)();
      this.ruleForm.creditCode = e;
    },
    // 组织地址
    checkOrganizationAddress(e) {
      this.$options.methods.fn.bind(this)();
      this.ruleForm.organizationAddress = e;
    },
    // 法人姓名
    checkLegalName(e) {
      this.$options.methods.fn.bind(this)();
      this.ruleForm.legalName = e;
    },
    // 法人身份证号
    checkLegalNumber(e) {
      if (e.length > 18) {
        this.ruleForm.legalNumber = e.slice(0, 18);
      }
      this.$options.methods.fn.bind(this)();
      this.ruleForm.legalNumber = e;
    },

    // 操作人姓名
    checkOperatorName(e) {
      this.$options.methods.fn.bind(this)();
      this.ruleForm.operatorName = e;
    },
    // 操作人身份证号
    checkOperatorNumber(e) {
      if (e.length > 18) {
        this.ruleForm.operatorNumber = e.slice(0, 18);
      }
      this.$options.methods.fn.bind(this)();
      this.ruleForm.operatorNumber = e;
    },
    // 操作人邮箱
    checkOperatorEmail(e) {
      this.$options.methods.fn.bind(this)();
      this.ruleForm.operatorEmail = e;
    },
    // 操作人手机号
    checkOperatorPhone(e) {
      if (e.length > 11) {
        this.ruleForm.operatorPhone = e.slice(0, 11);
      }
      this.$options.methods.fn.bind(this)();
      this.ruleForm.operatorPhone = e;
    },
    // 登录密码
    checkOperatorPassword(e) {
      this.$options.methods.fn.bind(this)();
      this.ruleForm.operatorPassword = e;
    },
    // 登录密码确认
    checkOperatorConfirmPwd(e) {
      this.$options.methods.fn.bind(this)();
      this.ruleForm.operatorConfirmPwd = e;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/register.scss";
</style>