// 校验手机号
function isvalidPhone(str) {
    let reg = /^1[3|4|5|7|8][0-9]\d{8}$/
    return reg.test(str)
}

// 校验身份证号
function isvalidIdNumber(str) {
    let reg = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i
    return reg.test(str)
}

// 校验邮箱
function isvalidEmail(str) {
    let reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    return reg.test(str)
}

export default {
    isvalidPhone,
    isvalidIdNumber,
    isvalidEmail
}